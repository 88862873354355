<template>
  <div
    id="media-buttons"
    class="tw-fixed tw-top-0 tw-table tw-h-full tw-right-2"
  >
    <div class="tw-table-cell tw-align-middle">
      <section class="tw-flex tw-flex-col ct">
        <button @click="prev">
          <i class="fas fa-fast-backward" />
        </button>
        <button @click="play">
          <i class="fas fa-play" />
        </button>
        <button @click="pause">
          <i class="fas fa-pause" />
        </button>
        <button @click="stop">
          <i class="fas fa-stop" />
        </button>
        <button @click="next">
          <i class="fas fa-fast-forward" />
        </button>
        <button @click="info">
          <i class="fas fa-info" />
        </button>
      </section>
    </div>

    <b-modal
      id="song-info"
      size="md"
      :scrollable="true"
      :centered="true"
      :hide-header="false"
      :hide-footer="true"
    >
      <template #modal-title>
        <div class="tw-flex tw-items-center tw-text-lg lg:tw-text-lg">
          Song Info
        </div>
      </template>

      <div class="tw-flex tw-flex-row tw-items-center">
        <div class="tw-w-32 tw-my-4">
          ID:
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-font-semibold">
          {{ songInfo.id }}
        </div>
      </div>

      <div class="tw-flex tw-items-center">
        <div class="tw-w-32 tw-my-4">
          Artist:
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-font-semibold">
          {{ songInfo.artist }}
          <a
            class="tw-p-4 tw-ml-2 tw-text-xl tw-text-white tw-bg-blue-500 tw-rounded-lg tw-cursor-pointer active"
            @click="playArtist(songInfo.artist)"
          >Play</a>
          <a
            class="tw-p-4 tw-ml-2 tw-text-xl tw-text-white tw-bg-blue-500 tw-rounded-lg tw-cursor-pointer active"
            @click="searchArtist(songInfo.artist)"
          >Search</a>
        </div>
      </div>

      <div class="tw-flex tw-flex-row tw-items-center">
        <div class="tw-w-32 tw-my-4">
          Title:
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-font-semibold">
          {{ songInfo.title }}
        </div>
      </div>

      <div class="tw-flex tw-flex-row tw-items-center">
        <div class="tw-w-32 tw-my-4">
          Folders:
        </div>
        <div class="tw-font-semibold">
          <div
            v-for="(folder, index) in songInfo.folders"
            :key="index"
            class="tw-flex tw-flex-row tw-items-center"
          >
            {{ folder }}
            <a
              class="tw-p-4 tw-ml-2 tw-text-xl tw-text-white tw-bg-blue-500 tw-rounded-lg tw-cursor-pointer active"
              @click="playFolder(folder)"
            >Play</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  data() {
    return {
      songInfo: {},
    };
  },
  computed: {
    ...sync([
      'webamp/song', //
      'webamp/songs', //
    ]),
  },

  methods: {
    prev() {
      Bus.$emit('player:press-button', 'prev');
    },
    play() {
      Bus.$emit('player:press-button', 'play');
    },
    pause() {
      Bus.$emit('player:press-button', 'pause');
    },
    stop() {
      Bus.$emit('player:press-button', 'stop');
    },
    next() {
      Bus.$emit('player:press-button', 'next');
    },

    info() {
      // console.log('song', this.song);
      // console.log('songs', this.songs);
      this.loadSongInfo(this.song.id);
      this.$root.$emit('bv::toggle::modal', 'song-info');
    },

    async loadSongInfo(songId) {
      await this.$http.get(`/api/v1/songs/${songId}`).then((response) => {
        // console.log('song:info.response', response.data);
        this.songInfo = response.data;
      });
    },

    playArtist(artist) {
      Bus.$emit('songs:fetch', {
        artist,
      });
    },

    searchArtist(artist) {
      Bus.$emit('songs:fetch', {
        query: artist,
      });
    },

    playFolder(folder) {
      Bus.$emit('songs:fetch', {
        path: folder,
      });
    },
  },
};
</script>

<style>
html {
  font: 50% 'Comfortaa', cursive;
}

section button {
  @apply tw-bg-gray-300 tw-w-24 tw-h-24 tw-rounded-full;
}

section button i {
  @apply tw-text-blue-500 tw-text-4xl;
}
</style>
